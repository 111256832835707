import type { VetoManager, VetoStepAvailable, VetoStepDone } from "../VetoManager";
import { VetoEntry } from "./VetoEntry";

const VetoEntryContainer = ({ step, veto, canMove, current, process, cache, mode }: {  mode: string,process: VetoStepDone[] | undefined; cache: number, step: VetoStepAvailable, veto: VetoManager, current: VetoStepAvailable | null, canMove: boolean, }) => {
    if(step.type === 'side') return null;

    const steps = veto.instruction.steps;

    const stepIndex = steps.findIndex(target => target.id === step.id);//.indexOf(step);

    const nextStep = steps[stepIndex+1];
    const nextStepData = veto.progress[stepIndex+1]
    return <VetoEntry
        mode={mode}
        data={veto.progress[stepIndex] || null}
        canMove={canMove && (current?.id === step.id || (nextStep && current?.id === nextStep.id && nextStep.type === 'side'))}
        step={step}
        veto={veto}
        current={current}
        cache={cache}
        next={nextStepData || null}
    />
}

const VetoView = ({ current, veto, canMove, cache, process, mode }: { mode: string, process: VetoStepDone[] | undefined; current: VetoStepAvailable | null, canMove: boolean, veto: VetoManager, cache: number,  }) => {
    return (
        <div id="veto" className="content">
            { veto.instruction.steps.map((step, i) => <VetoEntryContainer mode={mode} process={process} cache={cache} key={`${step.type}-${i}`} canMove={canMove} current={current} veto={veto} step={step} />)}
        </div>
    )
}

export { VetoView };