import * as React from "react"
import { SVGProps } from "react"
const Lan = ({ shapeColor, ...props}: SVGProps<SVGSVGElement> & { shapeColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={123}
    height={116}
    fill="none"
    {...props}
  >
    <path
      fill={shapeColor}
      stroke={shapeColor}
      d="M74.918.105H48.692c-4.808 0-8.72 3.913-8.72 8.722v26.227c0 4.809 3.912 8.72 8.72 8.72h26.226c4.81 0 8.72-3.911 8.72-8.72V8.827c0-4.81-3.91-8.722-8.72-8.722Zm4.394 34.946a4.396 4.396 0 0 1-4.393 4.392H48.693A4.396 4.396 0 0 1 44.3 35.05V8.825a4.397 4.397 0 0 1 4.392-4.393h26.226a4.398 4.398 0 0 1 4.393 4.393v26.227ZM107.696 80.95h.008a2.16 2.16 0 0 0 2.159-2.16v-7.675c0-4.81-3.911-8.72-8.72-8.72H63.969V50.327c0-1.194-.967-2.16-2.16-2.16H61.8c-1.194 0-2.16.966-2.16 2.16v12.068H22.466c-4.808 0-8.72 3.91-8.72 8.72v7.676a2.16 2.16 0 0 0 2.16 2.16h.009a2.16 2.16 0 0 0 2.16-2.16v-7.675a4.394 4.394 0 0 1 4.391-4.393h37.175v12.068a2.16 2.16 0 0 0 2.16 2.16h.008a2.16 2.16 0 0 0 2.16-2.16V66.723h37.175a4.395 4.395 0 0 1 4.393 4.392v7.676a2.158 2.158 0 0 0 2.159 2.16ZM116.442 85.34H98.955c-3.6 0-6.534 2.93-6.534 6.534v17.484a6.543 6.543 0 0 0 6.534 6.537h17.487a6.543 6.543 0 0 0 6.534-6.537V91.874a6.542 6.542 0 0 0-6.534-6.534Zm2.205 24.018a2.21 2.21 0 0 1-2.205 2.209H98.955a2.21 2.21 0 0 1-2.205-2.209V91.874c0-1.218.99-2.203 2.205-2.203h17.487c1.216 0 2.205.986 2.205 2.203v17.484ZM53.06 85.34a6.543 6.543 0 0 0-6.535 6.534v17.484a6.543 6.543 0 0 0 6.535 6.537h17.485a6.543 6.543 0 0 0 6.536-6.537V91.874a6.542 6.542 0 0 0-6.536-6.534H53.06Zm19.694 6.534v17.484a2.21 2.21 0 0 1-2.207 2.209H53.06a2.21 2.21 0 0 1-2.207-2.209V91.874c0-1.218.99-2.203 2.207-2.203h17.485c1.218 0 2.209.985 2.209 2.203ZM7.166 115.894h17.487c3.6 0 6.534-2.93 6.534-6.536V91.874a6.543 6.543 0 0 0-6.534-6.535H7.166a6.543 6.543 0 0 0-6.534 6.535v17.484a6.542 6.542 0 0 0 6.534 6.536Zm-2.205-24.02c0-1.218.989-2.203 2.205-2.203h17.487c1.217 0 2.205.986 2.205 2.203v17.484c0 1.219-.989 2.209-2.205 2.209H7.166a2.21 2.21 0 0 1-2.205-2.209V91.874Z"
    />
  </svg>
)
export default Lan;
