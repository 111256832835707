import API, { User, VetoHistory } from "./";
import React, { createContext, useContext, useEffect, useState } from "react";
import useUser from "./user";

export type Workspace = {
    id: number;
    name: string;
    permissions: string[];
    ownerId: number;
    enterpriseLicenseId: number;
    available: boolean;
  }
  
export interface WorkspaceContextProps {
    workspaces: Workspace[] | null;
    reloadWorkspaces: () => Promise<Workspace[] | null>;
    loading: boolean;
    workspace: Workspace | null,
    setWorkspace: (workspace: Workspace | null) => void
}

export const WorkspaceContext = createContext<WorkspaceContextProps>(
    {} as WorkspaceContextProps
);

export const WorkspaceProvider = ({ children }: { children: React.ReactNode }) => {
    const [workspaces, setWorkspaces] = useState<Workspace[] | null>(null);
    const [workspace, setWorkspace] = useState<Workspace | null>(null);
    const [loading, setLoading] = useState(true);

    const user = useUser();

    useEffect(() => {
        reloadWorkspaces();
    }, [user.loading, user.user]);

    const reloadWorkspaces = async () => {
        setLoading(true);

        const result = await API.workspaces();

        if(result.ok){
            setWorkspaces(result.data?.filter(Boolean) || []);
    
            setLoading(false);
    
            return result.data || null;
        }
        setLoading(false);

        return null;
    };


    return (
        <WorkspaceContext.Provider
            value={{
                workspaces,
                loading,
                workspace,
                setWorkspace,
                reloadWorkspaces,
            }}
        >
            {children}
        </WorkspaceContext.Provider>
    );
};

const useWorkspace = () => {
    const context = useContext(WorkspaceContext);
    if (context === undefined) {
        throw new Error("useWorkspace must be used within a UserProvider");
    }
    return context;
};

export default useWorkspace;
