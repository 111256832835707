import * as React from "react"
import { SVGProps } from "react"

const Hamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path stroke="#fff" d="M0 .5h22M0 8.5h22M0 4.5h22" />
  </svg>
)

export default Hamburger
