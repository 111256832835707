interface Config {
  isDev: boolean;
  apiURL: string;
  basename: string;
  stripeKey: string;
  webPassword?: string;
}

const configs = {
  local: {
    isDev: false,
    apiURL: "http://192.168.50.71:5000/",
    basename: "/app",
  },
  dev: {
    isDev: true,
    apiURL: "https://api-dev-2.lhm.gg/",
    basename: "/",
  },
  prod: {
    isDev: false,
    apiURL: "https://api.lhm.gg/",
    basename: "/",
  },
};

const config = configs.prod;
export default config;
