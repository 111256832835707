import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { UserProvider } from "./api/user";
import { VetoHistoryProvider } from "./api/vetos";
import { WorkspaceProvider } from "./api/workspaces";

const root = ReactDOM.createRoot(
  document.getElementById("app-root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <UserProvider>
      <WorkspaceProvider>
        <VetoHistoryProvider>
          <App />
        </VetoHistoryProvider>
      </WorkspaceProvider>
    </UserProvider>
  </React.StrictMode>
);
