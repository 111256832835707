import EventEmitter from "events";

export const events = new EventEmitter();

type VetoType = "ban" | "pick" | "decider";

export type Side = 'CT' | 'T' | 'NO';

export type TeamType = "left" | "right";

export type VetoStepInstruction = { id: number, team: TeamType, step: VetoType | "side" };

export type VetoStepDone = {
    type: VetoType,
    map: string,
    team: TeamType | null,
    id: number
} | {
    type: 'side',
    team: TeamType,
    map: string,
    side: Side,
    id: number
}

export type VetoStepAvailable = {
    id: number,
    type: Exclude<VetoType, "decider">,
    team: TeamType,
} | {
    id: number,
    type: "decider",
    team: null
} | {
    id: number,
    type: 'side',
    team: TeamType
}

type VetoInstruction = {
    bo: number,
    steps: VetoStepAvailable[]
}

// All Valorant maps: ['bind', 'haven', 'split', 'ascent', 'icebox', 'breeze', 'fracture', 'pearl', 'lotus', 'sunset', 'abyss']

export const availableMaps = {
    'csgo': ['de_mirage', 'de_nuke', 'de_anubis', 'de_vertigo', 'de_inferno', 'de_dust2', 'de_ancient'],
    'valorant': ['abyss', 'ascent','bind', 'haven', 'pearl', 'split', 'sunset']
};

export const sideNames = {
    'csgo': {
        'CT': 'CT',
        'T': 'T'
    },
    'valorant': {
        'CT': 'DEF',
        'T': 'ATK'
    }
}

const instructions: VetoInstruction[] = [
    {
        bo: 1,
        steps: [
            { type: 'ban', team: 'left', id: 0 },
            { type: 'ban', team: 'right', id: 1 },
            { type: 'ban', team: 'left', id: 2 },
            { type: 'ban', team: 'right', id: 3 },
            { type: 'ban', team: 'left', id: 4 },
            { type: 'ban', team: 'right', id: 5 },
            { type: 'decider', team: null, id: 6 },
        ]
    },
    {
        bo: 3,
        steps: [
            { type: 'ban', team: 'left', id: 0 },
            { type: 'ban', team: 'right', id: 1 },
            { type: 'pick', team: 'left', id: 2 },
            { type: 'side', team: "right", id: 3  },
            { type: 'pick', team: 'right', id: 4 },
            { type: 'side', team: "left", id: 5  },
            { type: 'ban', team: 'left', id: 6 },
            { type: 'ban', team: 'right', id: 7 },
            { type: 'decider', team: null, id: 8 },
        ]
    },
    {
        bo: 5,
        steps: [
            { type: 'ban', team: 'left', id: 0 },
            { type: 'ban', team: 'right', id: 1 },
            { type: 'pick', team: 'left', id: 2 },
            { type: 'side', team: "right", id: 3  },
            { type: 'pick', team: 'right', id: 4 },
            { type: 'side', team: "left", id: 5  },
            { type: 'pick', team: 'left', id: 6 },
            { type: 'side', team: "right", id: 7  },
            { type: 'pick', team: 'right', id: 8 },
            { type: 'side', team: "left", id: 9  },
            { type: 'decider', team: null, id: 10 },
        ]
    },
    {
        bo: 2,
        steps: [
			{ type: 'ban', team: 'left', id: 0 },
			{ type: 'ban', team: 'right', id: 1 },
			{ type: 'ban', team: 'left', id: 2 },
			{ type: 'ban', team: 'right', id: 3 },
			{ type: 'pick', team: 'left', id: 4 },
			{ type: 'side', team: 'right', id: 5 },
			{ type: 'pick', team: 'right', id: 6 },
			{ type: 'side', team: 'left', id: 7 }
        ]
        
    }
]
export type TeamInfo = {
    name: string,
    logo: string | null,
    lhmId: string | null
}

export type VetoSetup = {
    bo: number,
    left: TeamInfo,
    right: TeamInfo,
    maps: string[];
    process: VetoStepDone[];
    now: VetoStepInstruction | null;
    id: string;
    isLan: boolean;
    game: 'csgo' | 'valorant';
    ownerTeam?: number | null,
    owner?: number | null,
}

export type VetoSetupWithId = {
    bo: number,
    left: TeamInfo & { id: string },
    right: TeamInfo & { id: string },
    maps: string[];
    process: VetoStepDone[];
    now: VetoStepInstruction | null;
    id: string;
    game: 'csgo' | 'valorant';
}

const stepInstructionToAvailable = (step: VetoStepInstruction | null): VetoStepAvailable | null => {
    if(!step) return null;

    if(step.step === 'decider') {
        return ({
            type: "decider",
            team: null,
            id: step.id
        });
    }
    if(step.step === "side"){
        return ({
            type: "side",
            team: step.team,
            id: step.id
        })
    }
    return ({
        type: step.step,
        team: step.team,
        id: step.id
    })
}

class VetoManager {
    instruction: VetoInstruction;
    currentStep: VetoStepAvailable | null;
    progress: VetoStepDone[];
    teams: Record<TeamType, TeamInfo>;
    game: 'csgo' | 'valorant';
    constructor(bo = 3, game: 'csgo' | 'valorant' = 'csgo', left?: TeamInfo, right?: TeamInfo) {
        const instruction = instructions.find(instr => instr.bo === bo) as VetoInstruction;
        this.instruction = instruction;
        this.currentStep = instruction.steps[0];
        this.progress = [];
        this.game = game;
        this.teams = {
            left: left || { name: "Team One", logo: null, lhmId: '' },
            right: right || { name: "Team Two", logo: null, lhmId: '' },
        }
    }
    performStep = (map: string, side: Side = 'NO', locally = false) => {
        const { currentStep, instruction } = this;
        if (!currentStep) return;
        const performedStep: VetoStepDone = currentStep.type === 'side' ? { ...currentStep, map, side}  : { ...currentStep, map };
        this.progress = [...this.progress, performedStep];
        const nextStep = instruction.steps[instruction.steps.findIndex(step => step.id === currentStep.id) + 1];
        this.currentStep = nextStep || null;
        //('updated step x', this.currentStep, instruction.steps.indexOf(currentStep) + 1)
        events.emit('step', performedStep, locally, nextStep);

        if (this.currentStep?.type === 'decider') {
            const leftOver = availableMaps[this.game].find(map => !this.progress.map(step => step.map).includes(map));
            if (!leftOver) return;

            this.performStep(leftOver);
        }
    }

    updateState = (setup: VetoSetup) => {
        const startIndex = this.progress.length;
        
        this.teams.left.name = setup.left.name;
        this.teams.left.logo = setup.left.logo;
        
        this.teams.right.name = setup.right.name;
        this.teams.right.logo = setup.right.logo;

        for(let i = startIndex; i < setup.process.length; i++){
            const processStep = setup.process[i];
            this.performStep(processStep.map, processStep.type === 'side' ? processStep.side : 'NO');
        }
    }

    forceUpdateState = (setup: VetoSetup) => {
        /*this.progress = [];
        for(const step of setup.process){
            this.performStep(step.map, step.type === "side" ? step.side : 'NO');
        }*/

        
        const instruction = instructions.find(instr => instr.bo === setup.bo) as VetoInstruction;
        this.teams.left.name = setup.left.name;
        this.teams.left.logo = setup.left.logo;
        this.teams.right.name = setup.right.name;
        this.teams.right.logo = setup.right.logo;
        this.instruction = instruction;
        this.progress = setup.process;
        this.game = setup.game;
        this.currentStep = stepInstructionToAvailable(setup.now);
        
    }
}

export { VetoManager };
