import React from "react";
import LandingHTML from "../assets/LandingHTML";
import "./stylesLanding.css";

export default function Landing() {
  //   window.location.href = "/landing.html";

  return (
    <div
      //   className={styles.landing}
      dangerouslySetInnerHTML={{ __html: LandingHTML }}
    />
  );
}
