import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={13}
    fill="none"
    {...props}
  >
    <path
      d="M8.581 5.283a.891.891 0 0 1-.015.257c0 .005-.005.01-.005.01a.866.866 0 0 1-.224.406.866.866 0 0 1-1.215 0 1.443 1.443 0 0 0-1.555-.307c-.17.07-.33.173-.47.307l-1.34 1.33-1.624 1.608a1.408 1.408 0 0 0 0 1.999c.54.534 1.48.534 2.02 0L5.387 9.67c.52.351 1.105.588 1.725.692L5.367 12.09a3.15 3.15 0 0 1-2.224.91 3.15 3.15 0 0 1-2.224-.91 3.097 3.097 0 0 1 0-4.403l2.964-2.933a3.15 3.15 0 0 1 2.224-.91c.805 0 1.61.301 2.225.91a.827.827 0 0 1 .154.217l.02.035c.035.08.055.158.065.238.01.014.01.03.01.04Zm4.5-4.373A3.14 3.14 0 0 0 10.855 0c-.84 0-1.63.322-2.225.91l-1.75 1.732c.61.103 1.2.336 1.725.692l1.235-1.222c.54-.534 1.48-.534 2.02 0a1.4 1.4 0 0 1 0 1.999l-.545.539L8.9 7.044c-.54.534-1.48.534-2.02 0a.866.866 0 0 0-1.214 0 .845.845 0 0 0 0 1.202 3.14 3.14 0 0 0 2.225.91c.84 0 1.63-.321 2.224-.91l2.42-2.394.544-.54c.595-.588.92-1.37.92-2.2 0-.832-.325-1.613-.92-2.202Z"
      fill="#BDF2D5"
    />
  </svg>
)

export default SvgComponent
