import React from 'react';
import './index.scss';

type Props = {
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
    clear?: boolean;
    children?: any,
    style?: React.CSSProperties
}
const Button = ({ onClick, disabled, loading, clear, children, style}: Props) => {
    return (<div className={`button-custom ${clear ? 'clear': ''} ${disabled ? 'disabled':''}`} onClick={!disabled && !loading ? onClick : undefined} style={style}>{loading ? 'Loading...' : children}</div>)
}
export default Button;