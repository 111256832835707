import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import useUser from "../api/user";
import Button from "../Components/Button";
import Input from "../Components/Input";
import "./index.scss";

const Register = () => {
  const user = useUser();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    if (!user.loading && user.user) {
      navigate("/app");
    }
  }, [user.user, user.loading]);

  const onEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (
      !email ||
      !password ||
      !username ||
      !password2 ||
      password !== password2 ||
      password.length <= 6 ||
      loading ||
      !checked
    )
      return;

    if (e.key === "Enter") register();
  };

  const register = async () => {
    if (
      !email ||
      !password ||
      !username ||
      !password2 ||
      password !== password2 ||
      password.length <= 6 ||
      loading ||
      !checked
    )
      return;
    if (!("grecaptcha" in window)) {
      return setError("Captcha blocked you");
    }

    setLoading(true);

    const grecaptcha: any = window["grecaptcha" as any];

    grecaptcha.ready(async () => {
      //return;
      try {
        const token: string = await grecaptcha.execute(
          "6LemTqoZAAAAAJpy-S_Bd6xTg7uQ5khVAw_98rpE",
          { action: "submit" }
        );

        const result = await api.register({
          email,
          username,
          password,
          token,
        });
        if (result.status === 200) {
          setError("");

          await api.login(email, password);
          user.reloadUser();
          setLoading(false);
          return;
        }

        const error = result.data?.message || result.data?.error;

        setError(error || "Something went wrong");
        setLoading(false);
      } catch (e) {
        setError("Something went wrong");
        setLoading(false);
      }
    });
  };

  return (
    <div className="login-container register-container">
      {error ? <div className="error">{error}</div> : null}
      <Input
        onChange={setEmail}
        value={email}
        placeholder={"Enter your email"}
        label="Email"
      />
      <Input
        onChange={setUsername}
        value={username}
        placeholder={"Enter your username"}
        label="Username"
      />
      <Input
        onChange={setPassword}
        type="password"
        value={password}
        placeholder={"Enter your password"}
        label="Password"
        onKeyDown={onEnter}
      />
      <Input
        onChange={setPassword2}
        type="password"
        value={password2}
        placeholder={"Confirm password"}
        label="Confirm password"
        onKeyDown={onEnter}
      />

      <div className="tnc-confirm-box">
        <div
          className={"tnc-checkbox" + (checked ? " selected" : "")}
          onClick={() => setChecked(!checked)}
        />
        <div className="tcpp-container">
          By signing up you agree to our{" "}
          <a href="https://lhm.gg/TermsAndConditions.pdf" target="_blank">
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a href="https://lhm.gg/PrivacyPolicy.pdf" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
      <Button
        onClick={register}
        disabled={
          !email ||
          !password ||
          !username ||
          !password2 ||
          password !== password2 ||
          password.length <= 6 ||
          loading ||
          !checked
        }
      >
        Sign up
      </Button>

      <div className="modal-footer-container">
        Already have an account? <Link to={"/app/login"}>Login in!</Link>
      </div>
    </div>
  );
};

export default Register;
