import React, { useState } from "react";
import "./App.css";
import "./veto/index.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Content from "./screens/Content";
import Login from "./screens/Login";
import { AllHistory } from "./Components/VetoHistory";
import ScrollToTop from "./Components/ScrollToTop";
import config from "./api/config";
import Register from "./screens/Register";
import Landing from "./screens/Landing";
function App() {
  const [clearVeto, setClearVeto] = useState<(() => void) | null>(null);

  return (
    <div className="App">
      <Router basename={config.basename}>
        <ScrollToTop>
          <Header clearVeto={clearVeto} />
          <div className="route-container">
            <Routes>
              <Route path="/app/history" element={<AllHistory />} />
              <Route path="/app/register" element={<Register />} />
              <Route path="/app/login" element={<Login />} />
              <Route
                path="/app/online"
                element={<Content setClearVeto={setClearVeto} />}
              />
              <Route
                path="/app/lan"
                element={<Content setClearVeto={setClearVeto} />}
              />
              <Route
                path="/app"
                element={<Content setClearVeto={setClearVeto} />}
              />
              <Route path="/" element={<Landing />} />
              {/* <Route path="/" element={<>{window.open("/landing.html")}</>} /> */}
            </Routes>
          </div>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
