import React from 'react';
import { VetoHistory, apiUrl } from "../api";
import { Logo } from "../Components/logo";

export const History = ({ veto }: { veto: VetoHistory }) => {
    return <div className="veto-history-entry ">
        <details>

            <summary>
                <div className="veto-entry-teams">
                    {veto.data.teamOne.name} vs {veto.data.teamTwo.name}
                </div>
                <div className="veto-entry-maps">
                    {veto.data.entries.filter(entry => entry.type !== "ban").map(entry => entry.mapName).join(" | ")}
                </div>
                <div className="veto-entry-date">
                    {new Date(veto.createdAt).toLocaleDateString()}
                </div>
            </summary>
            <div className="veto-picks">
            <div className="veto-single logos">
                {
                    veto.data.teamOne.id ? <Logo url={`veto/team/logo/${veto.data.teamOne.id}?game=${veto.game}`} /> : null 
                }
                <div className="logo-separator" />
                {
                    veto.data.teamTwo.id ? <Logo url={`veto/team/logo/${veto.data.teamTwo.id}?game=${veto.game}`} /> : null 
                }
            </div>
            {
                veto.data.entries.map(entry => (
                    <div className={`veto-single ${entry.team || ''} ${entry.type}`}>
                        <div className="team-one">{entry.mapName}</div>
                        <div className="veto-type">{entry.type}</div>
                        <div className="team-two">{entry.mapName}</div>
                    </div>
                ))
            }
            </div>
        </details>
    </div>
}