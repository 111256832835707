import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import useUser from "../api/user";
import Button from "../Components/Button";
import Input from "../Components/Input";
import "./index.scss";

const Login = () => {
  const user = useUser();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [token, setToken] = useState("");
  const [totp, setTotp] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    if (!user.loading && user.user) {
      navigate("/app");
    }
  }, [user.user, user.loading]);

  const onEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (!email || !password) return;
    if (totp && !token) return;

    if (e.key === "Enter") logIn();
  };

  const logIn = async () => {
    const result = await api.login(email, password, totp ? token : undefined);

    if (result.status === 200) {
      setError("");
      user.reloadUser();
    }

    if (result.data?.error === "You need to pass your one-time token") {
      setTotp(true);
      setError("");
    } else if (result.data.message) {
      setError(result.data.message);
    }
  };

  if (totp) {
    return (
      <div className="login-container">
        {error ? <div className="error">{error}</div> : null}
        <Input
          onChange={setToken}
          value={token}
          placeholder={"Enter your one-time token"}
          label="Enter your one-time token"
          onKeyDown={onEnter}
        />
        <Button onClick={logIn} disabled={!token}>
          Log in
        </Button>
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="login-title-text">LHM VETO</div>
      <div className="login-text">Login</div>
      {error ? <div className="error">{error}</div> : null}
      <Input
        onChange={setEmail}
        value={email}
        placeholder={"Enter your email"}
        label="Email"
      />
      <Input
        onChange={setPassword}
        type="password"
        value={password}
        placeholder={"Enter your password"}
        label="Password"
        onKeyDown={onEnter}
      />
      <div id="forgot-password">
        <a href={`https://lhm.gg/remember`}>Forgot password?</a>
      </div>
      <Button onClick={logIn} disabled={!email || !password}>
        Log in
      </Button>
      <div className="modal-footer-container">
        Don't have an account? <Link to={"/register"}>Register now!</Link>
      </div>
    </div>
  );
};

export default Login;
