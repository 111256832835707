import API, { User } from "./";
import React, { createContext, useContext, useEffect, useState } from "react";

export interface UserContextProps {
  user: User | null;
  reloadUser: () => Promise<User | null>;
  loading: boolean;
  signOut: () => Promise<void>;
}

export const UserContext = createContext<UserContextProps>(
  {} as UserContextProps
);

const prefetch = API.getUser();

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    prefetch.then(({ data }) => {
      setLoading(false);
      if(!data?.user) return;

      setUser(data.user);
    })
    //reloadUser();
  }, []);

  const reloadUser = async () => {
    setLoading(true);

    const result = await API.getUser();
    if (result.status === 200) {
      setUser(result.data?.user || null);
    } else {
      console.error("User reload error:", result.data);
    }
    setLoading(false);

    return result.data?.user || null;
  };

  const signOut = async () => {
    setLoading(true);
    setUser(null);
    setLoading(false);
  };


  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        reloadUser,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default useUser;
