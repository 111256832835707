import { useEffect, useState } from "react";
import { VetoView } from "./../veto";
import {
  events,
  TeamType,
  VetoManager,
  VetoSetup,
  VetoSetupWithId,
  VetoStepAvailable,
  VetoStepDone,
} from "./../VetoManager";
import { socketUrl } from "./../api/socket";
import api, { VetoSetupPayload } from "../api";
import VetoLink from "./VetoLink";
import useUser from "../api/user";
import { HeaderMenu } from "../Components/HeaderMenu";
import Button from "../Components/Button";
import useWorkspace from "../api/workspaces";
import { SimpleWebSocket } from "simple-websockets";
import { useNavigate } from "react-router-dom";

export interface VetoTeamInfo {
  name: string;
  logo: string;
  lhmId: string | null;
}

let timeout: NodeJS.Timeout | null = null;
let inited = false;

function VetoHandler({
  mode,
  left,
  right,
  bo = 3,
  matchId,
  game,
}: {
  mode: string;
  left?: VetoTeamInfo;
  right?: VetoTeamInfo;
  bo?: number;
  matchId: string | null;
  game?: 'csgo' | 'valorant';
}) {
  const params = new URLSearchParams(window.location.search);
  const user = useUser();
  const navigate = useNavigate();

  const room = params.get("room");
  const token = params.get("token");

  const vetoId = params.get("vetoId") || params.get("obsVetoId");

  const [veto, setVeto] = useState<VetoManager | null>(null);
  const [vetoData, setVetoData] = useState<VetoSetup | null>(null);
  const [current, setCurrent] = useState<VetoStepAvailable | null>(null);
  const [userType, setUserType] = useState<TeamType | null>(null);

  const [cache, setCache] = useState<number>(Math.random());

  const [loading, setLoading] = useState(true);

  const [leftTeamUrl, setLeftTeamUrl] = useState("");
  const [rightTeamUrl, setRightTeamUrl] = useState("");

  const [showVeto, setShowVeto] = useState(false);

  const workspaces = useWorkspace();

  useEffect(() => {
    const socket = new SimpleWebSocket(socketUrl);

    const doPingInterval = setInterval(() => {
      if (!socket || socket._socket.readyState !== WebSocket.OPEN) return;

      socket.send("ping");
    }, 45000);
    const onVetoSummary = (vetoSetup: VetoSetupWithId) => {
      if (!vetoId && !token && !room && mode !== "online") {
        navigate(`/app/lan?vetoId=${vetoSetup.id}`);
      }
      setLoading(false);
      const veto = new VetoManager(
        vetoSetup.bo,
        vetoSetup.game,
        vetoSetup.left || left,
        vetoSetup.right || right
      );
      setVeto(veto);
      socket.send("registerVeto", vetoSetup.id, "", true);
      if (window.location.href.length > 0 && window.location.href[window.location.href.length - 1] === '/') {
        setLeftTeamUrl(
          `${window.location.href}online/?&room=${vetoSetup.id}&token=${vetoSetup.left.id}`
        );
        setRightTeamUrl(
          `${window.location.href}online/?&room=${vetoSetup.id}&token=${vetoSetup.right.id}`
        );
      } else {
        setLeftTeamUrl(
          `${window.location.href}/online/?&room=${vetoSetup.id}&token=${vetoSetup.left.id}`
        );
        setRightTeamUrl(
          `${window.location.href}/online/?&room=${vetoSetup.id}&token=${vetoSetup.right.id}`
        );
      }

      socket.on("vetoUpdate", (vetoSetup: VetoSetup) => {
        veto.forceUpdateState(vetoSetup);

        //const isWatching = !vetoId && !token && !room && mode === "online";

        // if (isWatching) {
        setVetoData(vetoSetup);
        //}
      });
    };
    const onConnectionLan = () => {
      if (!vetoId) return;

      socket.on("vetoSummary", onVetoSummary);
      socket.send("reRegisterVeto", vetoId);
    };

    if (room && token) {
      setLoading(false);
    } else if (vetoId) {
      if (socket._socket.readyState === WebSocket.OPEN) {
        onConnectionLan();
      }
      socket.on("connection", onConnectionLan);
    } else {
    }

    const veto = new VetoManager(bo, game, left, right);
    setVeto(veto);

    const createOnConnectionWithSocket = () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const onType = (type: TeamType) => {
        setUserType(type);
      };

      const onVetoUpdate = (vetoSetup: VetoSetup) => {
        veto.forceUpdateState(vetoSetup);
      
        setVetoData(vetoSetup);
      };

      const onStep = (step: VetoStepDone, locally: boolean) => {
        if (!locally) {
          socket.send("vetoStep", step);
        }
      };

      const onConnectionWithSocket = () => {
        if (room && token) {
        } else if (vetoId) {
        } else {
          socket.on("vetoSummary", onVetoSummary);

          const userId = user.user!.id;
          const ownerTeam = workspaces.workspace?.id || null;

          const payload: VetoSetupPayload = {
            game: game || "csgo",
            isLan: mode === "lan",
            bo,
            leftTeam: {
              name: left?.name || "Team One",
              logo: left?.logo || null,
              lhmId: left?.lhmId || null,
            },
            rightTeam: {
              name: right?.name || "Team Two",
              logo: right?.logo || null,
              lhmId: right?.lhmId || null,
            },
            matchId,
            identity: { owner: ownerTeam ? null : userId, ownerTeam },
          };
          api.createToken(payload, { signal }).then((res) => {
            if (!res.data?.token) {
              return;
            }
            const result = socket.send("createVeto", res.data.token);
            console.log(result);
          });
        }
        if (!room || !token) {
          return;
        }
        socket.on("type", onType);
        socket.on("vetoUpdate", onVetoUpdate);
        events.on("step", onStep);

        socket.send("registerVeto", room, token, false);
      };

      const onRemove = () => {
        socket.off("type", onType);
        socket.off("vetoUpdate", onVetoUpdate);
        events.off("step", onStep);
        controller.abort();
      };

      return { onConnectionWithSocket, onRemove };
    };

    const { onConnectionWithSocket, onRemove } = createOnConnectionWithSocket();

    if (socket._socket.readyState === WebSocket.OPEN) {
      onConnectionWithSocket();
    }

    const onStep = (step: VetoStepDone, locally: boolean) => {
      if (mode !== "lan" || !locally) return;
      socket.send("vetoStep", step);
    };
    events.on("step", onStep);
    socket.on("connection", onConnectionWithSocket);

    return () => {
      socket._socket.close();

      clearInterval(doPingInterval);

      socket.off("connection", onConnectionLan);
      socket.off("vetoSummary", onVetoSummary);

      socket.off("connection", onConnectionWithSocket);
      events.off("step", onStep);
      onRemove();

      // TODO: Verify
      setVeto(null);
      setVetoData(null);
      setCurrent(null);
      setUserType(null);
      setLeftTeamUrl("");
      setRightTeamUrl("");
    };
  }, []);

  useEffect(() => {
    setCurrent(veto?.currentStep || null);

    const onStep = () => {
      setCurrent(veto?.currentStep || null);
    };

    events.on("step", onStep);

    return () => {
      events.off("step", onStep);
    };
  }, [veto, veto?.progress]);

  if (loading) {
    return (
      <>
        <HeaderMenu status={"votes"} />
        <div className="content team-urls">Loading...</div>
      </>
    );
  }

  if (mode === "online" && !showVeto && !room && !token) {
    return (
      <>
        <HeaderMenu status={"votes"} />
        <div className="content team-urls">
          <VetoLink url={leftTeamUrl} team={left} />
          <VetoLink url={rightTeamUrl} team={right} />
          <Button
            style={{ width: "389px", maxWidth: "100%" }}
            onClick={() => setShowVeto(true)}
          >
            See votes
          </Button>
        </div>
      </>
    );
  }
  if (!veto) return null;
  console.log(mode, !!userType, current);
  return (
    <>
      <VetoView
        cache={cache}
        current={current}
        mode={mode}
        canMove={mode === "lan" || (!!userType && current?.team === userType)}
        veto={veto}
        process={vetoData?.process}
      />
    </>
  );
}

export { VetoHandler };
