import React from 'react';
import "./index.scss";
import { Team } from "../../api";
import { VetoTeamInfo } from "../../veto/VetoHandler";
import Button from "../Button";
import Input, { Hint, InputWithHints, Select } from "../Input";
import { toast } from 'react-toastify';
const  xD = () => toast.success('URL Copied', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
});
type Props = {
    teams: Team[],
    left: VetoTeamInfo,
    right: VetoTeamInfo,
    setLeft: (team: VetoTeamInfo) => void;
    setRight: (team: VetoTeamInfo) => void;
    accept: () => void;
    boType: string;
    setBo: (bo: string) => void;
}
const mapTeamToHint = (team: Team): Hint => ({ value: team._id, label: team.name })

const getTeamHints = (teams: Team[], input: string) => {
    if(!input) return teams.map(mapTeamToHint);

    return teams.filter(team => team.name.toLowerCase().includes(input.toLowerCase()) || team.shortName?.toLowerCase().includes(input.toLowerCase())).map(mapTeamToHint);
}

const MatchCreation = ({ teams, left, right, setLeft, setRight, accept, setBo, boType }: Props) => {
    const getTeam = (side: 'left'|'right') => (id: string) => {
        const target = teams.find(team => team._id === id);
        const team: VetoTeamInfo = { name: target?.name || '', logo: target?.logo || '', lhmId: target?._id || null };

        if(side === 'left'){
            setLeft(team)
        } else {
            setRight(team);
        }
    }

    const onInput = (side: 'left'|'right') => (name: string, logo = '') => {
        const team: VetoTeamInfo = { name, logo, lhmId: null };

        if(side === 'left'){
            setLeft(team)
        } else {
            setRight(team);
        }
    }

    const clear = () => {
        setLeft({ name: '', logo: '', lhmId: null });
        setRight({ name: '', logo: '', lhmId: null });
        setBo('bo3');
    }

    return (
        <div className="match-creation">
            <Select id="bo" onChange={setBo} value={boType} label="Type of series">
                <option value={'bo1'}>Best of 1</option>
                <option value={'bo2'}>Best of 2</option>
                <option value={'bo3'}>Best of 3</option>
                <option value={'bo5'}>Best of 5</option>
            </Select>
            <div className="match-teams">
            {/*<Select onChange={getTeam("left")} value={left.name} label="Team #1">
                <option value='' disabled>PICK TEAMS</option>
                {teams.map(team => <option value={team._id}>{team.name}</option>)}
    </Select>*/}
            <InputWithHints label="Team #1" placeholder="Team Name" value={left.name} onChange={onInput("left")} onSelect={hint => getTeam("left")(hint.value)} hints={getTeamHints(teams, left.name)}/>
            <div className="versus">VS</div>
            <InputWithHints label="Team #2" placeholder="Team Name" value={right.name} onChange={onInput("right")} onSelect={hint => getTeam("right")(hint.value)} hints={getTeamHints(teams, right.name)}/>

            {/*<Select onChange={getTeam("right")} value={right.name} label="Team #2">
                <option value='' disabled>PICK TEAMS</option>
                {teams.map(team => <option value={team._id}>{team.name}</option>)}
</Select>*/}
            </div>
            <div className="actions">
                <Button onClick={clear} clear>Clear Veto</Button>
                <Button disabled={!left.name || !right.name || left.name === right.name} onClick={accept}>Create veto</Button>
            </div>
        </div>
    )
}

export default MatchCreation;