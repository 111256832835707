import React from 'react';
import "./index.scss";

const HeaderMenuLabel = ({ children, active }: { children: string, active: boolean }) => {
    return (
        <>
            <div className={`menu-bar-label ${active ? 'active': ''}`}>{children}</div>
            <div className={`menu-bar-dot ${active ? 'active' : ''}`}></div>
        </>
    )
}

export const HeaderMenu = ({ status }: { status: "game" | "veto" | "mode" | "votes" | null }) => {
    if (!status) return null;

    return (
        <div className="menu-bar-container">
            <div className={`menu-bar ${status}`}>
                <div className={`status game`}><HeaderMenuLabel active>Select Game</HeaderMenuLabel></div>
                <div className={`status veto`}><HeaderMenuLabel active={status !== 'game'}>Create Veto</HeaderMenuLabel></div>
                <div className={`status mode`}><HeaderMenuLabel active={status === "votes" || status === "mode"}>Mode</HeaderMenuLabel></div>
                <div className={`status votes`}><HeaderMenuLabel active={status === "votes"}>Votes</HeaderMenuLabel></div>
            </div>
        </div>
    )
}
