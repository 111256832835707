import { TeamInfo, VetoSetup } from "../VetoManager";
import config from "./config";
import { Workspace } from "./workspaces";
export const apiUrl = config.apiURL;

export interface User {
    id: number;
    email: string;
    admin: boolean;
    username: string;
    banned: boolean;
    totp?: boolean;
    createdAt: string;
    license: {
        type: string
    }
}
export interface Team {
    _id: string;
    name: string;
    shortName: string;
    country: string;
    logo: string;
    extra: Record<string, string>;
}

interface RoundData {
    round: number;
    players: {
        [steamid: string]: PlayerRoundData;
    };
    winner: 'CT' | 'T' | null;
    win_type: 'bomb' | 'elimination' | 'defuse' | 'time';
}

interface PlayerRoundData {
    kills: number;
    killshs: number;
    damage: number;
}

export interface Team {
    _id: string;
    name: string;
    shortName: string;
    country: string;
    logo: string;
    extra: Record<string, string>;
}

type VetoType = 'ban' | 'pick' | 'decider';

interface Veto {
    teamId: string;
    mapName: string;
    side: 'CT' | 'T' | 'NO';
    type: VetoType;
    score?: {
        [key: string]: number;
    };
    rounds?: RoundData[];
    reverseSide?: boolean;
    winner?: string;
    game?: any;
    mapEnd: boolean;
}

type BOTypes = 'bo1' | 'bo2' | 'bo3' | 'bo5';

interface MatchTeam {
    id: string | null;
    wins: number;
}

interface MatchTeamWithData extends MatchTeam {
    data: Team | null;
}

export interface Match {
    id: string;
    current: boolean;
    left: MatchTeam;
    right: MatchTeam;
    matchType: BOTypes;
    vetos: Veto[];
    startTime: number;
}
export interface MatchWithTeams extends Match {
    left: MatchTeamWithData;
    right: MatchTeamWithData;
}

export interface CloudStorageData<T> {
    id: number;
    lhmId: string;
    data: T;
    game: 'csgo' | 'valorant';
    owner: number;
    createdAt: string;
    updatedAt: string;
}

export type VetoSetupPayload = { game: 'csgo' | 'valorant', isLan: boolean, bo: number, leftTeam: TeamInfo, rightTeam: TeamInfo, matchId: string | null, identity: { owner: number | null, ownerTeam: number | null } };

export type VetoHistory = {
    id: number,
    game: 'csgo' | 'valorant';
    data: {
        teamOne: {
            name: string;
            id: number | null;
        };
        teamTwo: {
            name: string;
            id: number | null;
        };
        entries: {
            type: "ban" | "pick" | "decider";
            mapName: string;
            team: "one" | 'two' | null;
            opponentSide: "CT" | "T" | null;
        }[];
    };
    createdAt: string;

}

type APIResponse<T> = {
    status: number,
    ok: boolean,
    data?: T
}

const apiHandler = async<T>(
    url: string,
    method = "GET",
    body?: any,
    opts?: RequestInit
): Promise<APIResponse<T>> => {
    const options: RequestInit = {
        method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
        ...opts,
    };
    if (body) {
        options.body = JSON.stringify(body);
    }
    let data: any = null;
    const response = await fetch(url, options);
    data = await response.text();

    try {
        data = JSON.parse(data);
    } catch {

    }

    const result = {
        status: response.status,
        ok: response.ok,
        data: data
    }

    return result;
};

export const apiV2 = async<T = any>(
    url: string,
    method = "GET",
    body?: any,
    options?: RequestInit
) => apiHandler<T>(`${apiUrl}${url}`, method, body, options);

// eslint-disable-next-line import/no-anonymous-default-export
const api = {
    login: (username: string, password: string, code?: string) => apiV2('auth', "POST", { username, password, code }),
    register: (creds: { username: string, email: string, password: string, token: string }) => apiV2("auth/register", "POST", creds),
    getUser: () => apiV2<{ user: User }>("auth/me"),
    getMatches: (game: 'csgo' | 'valorant', workspace?: number)  => apiV2<{ matches: CloudStorageData<Match>[], teams: CloudStorageData<Team>[] }>(workspace ? `veto/matches?teamId=${workspace}&game=${game}` : `veto/matches?game=${game}`),
    createToken: (body: VetoSetupPayload, opts?: RequestInit) => apiV2<{ token: any }>("veto/setup", "POST", body, opts),
    getHistory: (workspace?: number) => apiV2<VetoHistory[]>(!workspace ? 'veto/history' : `veto/history?teamId=${workspace}`),
    workspaces: () => apiV2<Workspace[]>('auth/workspaces'),
    unfinished: (workspace?: number) => apiV2<{ vetos: VetoSetup[]}>(!workspace ? 'vetos' : `vetos?teamId=${workspace}`),
    deleteVeto: (id: string, workspace?: number | null) => apiV2(!workspace ? `veto/${id}` : `veto/${id}?teamId=${workspace}`, 'DELETE')
};
(window as any).test = api;
export default api;
