import React, { useEffect, useState } from 'react';

interface Props {
  text?: string;
  type?: 'success' | 'error' | 'warning';
  timeout?: number;
  id?: number;
}

const Toast = (props: Props) => {
  const { text, type, timeout, id } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (text) {
      setShow(true)
      setTimeout(() => setShow(false), timeout || 3000);
    }
    else {
      setShow(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, id])

  return (
    <div className={`toast ${type} ${show ? 'visible' : 'hidden'}`}>
      {text || ''}
    </div>
  )
}

export default Toast;