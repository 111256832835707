import React, { Component, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }: { children: any }) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ left: 0, top: 1, behavior: 'auto'});
    }, [location.pathname]);

    return children;
}



export default ScrollToTop;