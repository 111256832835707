import config from "../api/config";
import { Logo } from "../Components/logo";
import { availableMaps, VetoManager, VetoStepAvailable, VetoStepDone, TeamType, Side, sideNames } from "../VetoManager";
import BanIcon from './../assets/ban.png';
import PickIcon from './../assets/pick.png';

const VetoMapOverview = ({ data, game }: { data: VetoStepDone | null, game: 'csgo' | 'valorant' }) => {
    if (!data) return <div className="veto-image-map"></div>
    return <div className="veto-image-map">
        <img src={`https://${config.isDev ? 'veto-dev.lhm.gg' : 'veto.lhm.gg'}/maps/${game}/${data.map.replace("de_", "")}.png`} />
        {data.type === 'ban' ? <img src={BanIcon} className="veto-result-icon" /> : null}
        {data.type === 'pick' || data.type === 'decider' ? <img src={PickIcon} className="veto-result-icon" /> : null}
    </div>
}

const titleCaseWord = (word: string) => {
    if (!word) {
        return word;
    }

    return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

const VetoDataView = ({ data, veto, side, isPicking, thumbnailLogo }: { data: VetoStepDone, veto: VetoManager, side: VetoStepDone | null, isPicking: boolean, thumbnailLogo?: string | null }) => {
    const otherTeam = data.team === 'left' ? 'right' : 'left' as TeamType;

    const isPickingSide = isPicking && data.type === 'pick';

    return (
        <>
            <div className={`veto-container ${data.type} ${isPicking ? 'now' : ''} ${isPickingSide ? 'highlight-small' : ''}`}>
                <Logo url={data.team ? veto.teams[data.team].logo : null} secondUrl={thumbnailLogo} />
                <div className="description column">
                    <div>
                        {/*veto.teams[data.team as TeamType].name} {data.type*/} {veto.game === 'csgo' ? data.map : titleCaseWord(data.map)}
                    </div>
                    {
                        side && side.type === 'side' && side.side !== 'NO' ? <div className="side-pick-info">{veto.teams[side.team].name} picks {sideNames[veto.game][side.side]}</div> : null
                    }
                    {
                        isPicking && data.type === 'pick' ? <div>{veto.teams[otherTeam].name} is picking side</div> : null
                    }
                </div>
                <VetoMapOverview data={data} game={veto.game}/>
            </div>
            { isPicking ? <div className={`active-line ${data.type}`} /> : null }
        </>
    )
}

const VetoEntry = ({ step, data, canMove, veto, next, cache, current, mode }: { mode: string, current: VetoStepAvailable | null, cache: number, step: VetoStepAvailable, data: VetoStepDone | null, canMove: boolean, veto: VetoManager, next: VetoStepDone | null }) => {
    const nextAvailableStep = veto.instruction.steps[veto.instruction.steps.findIndex(target => target.id === step.id) + 1] as VetoStepAvailable | undefined;
    const secondLogoUrl = nextAvailableStep && nextAvailableStep.type === 'side' ? veto.teams[nextAvailableStep.team].logo : null;
    const confirmSelection = () => {
        const value = (document.getElementById("now-picking") as HTMLSelectElement)?.value;

        if (!value) return;

        veto.performStep(value, undefined, mode === 'lan');
    }
    const confirmSideSelection = () => {
        const value = (document.getElementById("now-picking-side") as HTMLSelectElement)?.value;

        if (!value || !data) return;

        veto.performStep(data.map, value as Side, mode === 'lan');
    }

    if (step.type === 'decider') {
        return (
            <div className={`veto-container decider`}>
                <Logo url={data && data.team ? veto.teams[data.team].logo : null} secondUrl={secondLogoUrl} />
                <div className="description">
                    {
                        data ? `${veto.game === 'csgo' ? data.map : titleCaseWord(data.map)} left as decider` : 'Decider'
                    }
                </div>
                <VetoMapOverview data={data} game={veto.game}/>
            </div>
        )
    }
    if (data && !canMove) {
        return <VetoDataView thumbnailLogo={secondLogoUrl} data={data} veto={veto} side={next} isPicking={!!veto.currentStep && veto.currentStep.type === 'side' && (veto.instruction.steps.findIndex(target => target.id === step.id) + 1) === (veto.instruction.steps.findIndex(target => target.id === veto.currentStep!.id))} />
    }
    if (step.id !== veto.currentStep?.id) {
        if (canMove && veto.currentStep && veto.currentStep.type === 'side') {
            return <>
                <div className={`veto-container ${step.type} now highlight-small`}>
                    <Logo url={veto.teams[step.team].logo} secondUrl={secondLogoUrl} />
                    <div className="description">
                        <select id="now-picking-side">
                            <option value={'CT'}>{sideNames[veto.game].CT}</option>
                            <option value={'T'}>{sideNames[veto.game].T}</option>
                        </select>
                        <div className="confirm-selection" onClick={confirmSideSelection}>Confirm side</div>
                    </div>
                    <VetoMapOverview data={data} game={veto.game}/>
                </div>
                <div className={`active-line ${step.type}`} />
            </>
        }
        return (
            <div className={`veto-container ${step.type}`}>
                <Logo url={veto.teams[step.team].logo} secondUrl={secondLogoUrl} />
                {veto.teams[step.team].name}'s {step.type}
                <VetoMapOverview data={data} game={veto.game}/>
            </div>
        )
    }
    if (canMove) {
        return <>
            <div className={`veto-container ${step.type} now ${step.type === 'pick' ? 'highlight-big' : ''}`}>
                <Logo url={veto.teams[step.team].logo} secondUrl={secondLogoUrl} />
                <div className="description">
                    {step.type !== 'side' ? <select id="now-picking">
                        {availableMaps[veto.game].filter(map => !veto.progress.map(step => step.map).includes(map)).map(map => <option key={`${map}-${veto.instruction.steps.findIndex(target => target.id === step.id)}`} value={map}>{veto.game === 'csgo' ? map : titleCaseWord(map)}</option>)}
                    </select> : null}

                    <div className="confirm-selection" onClick={confirmSelection}>Confirm {step.type}</div>
                </div>
                <VetoMapOverview data={data} game={veto.game}/>
            </div>
            <div className={`active-line ${step.type}`} />
        </>
    }
    return <>
        <div className={`veto-container ${step.type} now ${step.type === 'pick' ? 'highlight-big' : ''} opponent`}>
            <Logo url={veto.teams[step.team].logo} secondUrl={secondLogoUrl} />
            <div className="description">
                {veto.teams[step.team].name} is now making their {step.type}
            </div>
            <VetoMapOverview data={data} game={veto.game}/>
        </div>
        <div className={`active-line ${step.type}`} />
    </>;
}

export { VetoEntry };