import API, { User, VetoHistory } from "./";
import React, { createContext, useContext, useEffect, useState } from "react";
import useUser from "./user";

export interface VetoHistoryContextProps {
    vetoHistory: VetoHistory[] | null;
    reloadVetos: (workspace: number) => Promise<VetoHistory[] | null>;
    loading: boolean;
}

export const VetoHistoryContext = createContext<VetoHistoryContextProps>(
    {} as VetoHistoryContextProps
);

export const VetoHistoryProvider = ({ children }: { children: React.ReactNode }) => {
    const [vetoHistory, setVetoHistory] = useState<VetoHistory[] | null>(null);
    const [loading, setLoading] = useState(true);

    const user = useUser();

    useEffect(() => {
        reloadVetos();
    }, [user.loading, user.user]);

    const reloadVetos = async (workspaceId = 0) => {
        setLoading(true);

        const result = await API.getHistory(workspaceId);
        if (result.status === 200) {
            setVetoHistory(result.data || null);
        } else {
            setVetoHistory(null);
            console.error("User reload error:", result.data);
        }
        setLoading(false);

        return result.data || null;
    };


    return (
        <VetoHistoryContext.Provider
            value={{
                vetoHistory,
                loading,
                reloadVetos,
            }}
        >
            {children}
        </VetoHistoryContext.Provider>
    );
};

const useVetoHistory = () => {
    const context = useContext(VetoHistoryContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

export default useVetoHistory;
