import React from 'react';
import { Match, MatchWithTeams } from "../../api";
import useUser from "../../api/user";
import Button from "../Button";
import "./index.scss";

export const MatchEntry = (
  { match, createVeto }: {
    match: MatchWithTeams;
    createVeto: (match: MatchWithTeams) => void;
  },
) => {
  let teamOne = "Team One";
  let teamTwo = "Team Two";

  if (match.left.data) {
    teamOne = match.left.data.name;
  }
  if (match.right.data) {
    teamTwo = match.right.data.name;
  }
  return (
    <div className="match-entry">
      <div className="title">
        {teamOne} vs {teamTwo} [{match.matchType.toUpperCase()}]
      </div>
      <Button onClick={() => createVeto(match)}>Create Veto</Button>
    </div>
  );
};

export const MatchList = (
  { matches, createVeto, blur }: {
    blur: boolean;
    matches: MatchWithTeams[];
    createVeto: (match: MatchWithTeams) => void;
  },
) => {
  const user = useUser();

  //const isAvailable = user.user?.
  return (
    <div className={`match-list ${blur || matches.length === 0 ? "blurred" : ""}`}>
      {blur
        ? (
          <>
            <div className="upgrade-info">
              <p>
                Choose the correct match for Veto from the LHM Cloud Storage.
                The result of the Veto will be also saved to the Cloud!
              </p>
              <a href="https://lhm.gg/pricings" target="_blank">
                <Button>Upgrade plan</Button>
              </a>
            </div>
            <div className="match-list-container-blur">
              <div className="small-text">Choose match</div>
              {matches.map((match) => (
                <MatchEntry
                  key={match.id}
                  match={match}
                  createVeto={createVeto}
                />
              ))}
            </div>
          </>
        )
        : (matches.length === 0
          ? (
            <>
              <div className="upgrade-info">
                <p>
                  You have no matches available. This could mean that all your
                  matches have ended or you don't have any matches created. Make
                  sure you have Cloud Storage enabled.
                </p>
              </div>
              <div className="match-list-container-blur">
                <div className="small-text">Choose match</div>
                {matches.map((match) => (
                  <MatchEntry
                    key={match.id}
                    match={match}
                    createVeto={createVeto}
                  />
                ))}
              </div>
            </>
          )
          : (
            <>
              <div className="small-text">Choose match</div>
              {matches.map((match) => (
                <MatchEntry
                  key={match.id}
                  match={match}
                  createVeto={createVeto}
                />
              ))}
            </>
          ))}
    </div>
  );
};
