import React, { useEffect, useState } from "react";
import api, { CloudStorageData, Match, MatchWithTeams, Team } from "./../api";
import { MatchList } from "./../Components/matches";
import { VetoHandler, VetoTeamInfo } from "./../veto/VetoHandler";
import useUser from "./../api/user";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MatchCreation from "../Components/MatchSelection";
import Lan from "../assets/Lan";
import Online from "../assets/Online";
import Button from "../Components/Button";
import { HeaderMenu } from "../Components/HeaderMenu";
import { VetoTitle } from "../Components/PageTitle";
import { Select } from "../Components/Input";
import useWorkspace from "../api/workspaces";
import useVetoHistory from "../api/vetos";
import { TeamType, VetoSetup } from "../VetoManager";
import { copyLink } from "../veto/VetoLink";
import Copy from "../assets/Copy";
import { ReactComponent as CS2 } from '../assets/cs2.svg';
import { ReactComponent as Valorant } from '../assets/valorant.svg';

import { toast, ToastContainer } from "react-toastify";
import Toast from "../veto/Toast";
import { Logo } from "../Components/logo";
import config from "../api/config";

const mapMatchesAndTeam =
  (teams: Team[]) =>
  (match: CloudStorageData<Match>): MatchWithTeams => {
    const data = match.data as MatchWithTeams;
    data.left.data = teams.find((team) => team._id === data.left.id) || null;
    data.right.data = teams.find((team) => team._id === data.right.id) || null;

    return data;
  };

type Step = /*'login' | */ "game" | "setup" | "mode" | "veto" | "starting";

const fakeMatches: MatchWithTeams[] = [
  {
    id: "23",
    current: false,
    matchType: "bo3",
    vetos: [],
    startTime: 0,
    left: {
      id: null,
      wins: 0,
      data: {
        _id: "string",
        name: "Triangles",
        shortName: "string",
        country: "string",
        logo: "",
        extra: {},
      },
    },
    right: {
      id: null,
      wins: 0,
      data: {
        _id: "string",
        name: "Owles",
        shortName: "string",
        country: "string",
        logo: "",
        extra: {},
      },
    },
  },
  {
    id: "253",
    current: false,
    matchType: "bo3",
    vetos: [],
    startTime: 0,
    left: {
      id: null,
      wins: 0,
      data: {
        _id: "string",
        name: "Triangles",
        shortName: "string",
        country: "string",
        logo: "",
        extra: {},
      },
    },
    right: {
      id: null,
      wins: 0,
      data: {
        _id: "string",
        name: "Owles",
        shortName: "string",
        country: "string",
        logo: "",
        extra: {},
      },
    },
  },
  {
    id: "213",
    current: false,
    matchType: "bo3",
    vetos: [],
    startTime: 0,
    left: {
      id: null,
      wins: 0,
      data: {
        _id: "string",
        name: "Triangles",
        shortName: "string",
        country: "string",
        logo: "",
        extra: {},
      },
    },
    right: {
      id: null,
      wins: 0,
      data: {
        _id: "string",
        name: "Owles",
        shortName: "string",
        country: "string",
        logo: "",
        extra: {},
      },
    },
  },
];

const CopyButton = (
  props: { vetoId: string } | { room: string; token: string }
) => {
  const [toastText, setToastText] = useState("");
  const [toastId, setToastId] = useState(0);

  const showToast = (text: string) => {
    setToastText(text);
    setToastId(Math.random());
  };

  if ("vetoId" in props) {
    return (
      <div
        className="copy-link-div"
        onClick={() => {
          if (window.location.href.length > 0 && window.location.href[window.location.href.length - 1] === '/') {
            copyLink(`${window.location.href}lan?vetoId=${props.vetoId}`);
          } else {
            copyLink(`${window.location.href}/lan?vetoId=${props.vetoId}`);
          }
        
          showToast("URL Copied!");
        }}
      >
        <Copy />
        Click & Copy
        <Toast id={toastId} text={toastText} />
      </div>
    );
  }
  const { room, token } = props;
  return (
    <div
      className="copy-link-div"
      onClick={() => {
        if (window.location.href.length > 0 && window.location.href[window.location.href.length - 1] === '/') {
          copyLink(`${window.location.href}online/?&room=${room}&token=${token}`);
        } else {
          copyLink(`${window.location.href}/online/?&room=${room}&token=${token}`);
        }
        showToast("URL Copied!");
      }}
    >
      <Copy />
      <Toast id={toastId} text={toastText} />
    </div>
  );
};

const TeamPick: React.FC<{
  setStarting: () => void;
  team: VetoTeamInfo;
  active: boolean;
}> = ({ setStarting, team, active }) => {
  return (
    <div onClick={setStarting}>
      <div className={`mode-container starting ${active ? "active" : ""}`}>
        <div className="team-name-pick">
          <Logo url={team.logo} />
          <div className="team-name-container">{team.name}</div>
        </div>
      </div>
    </div>
  );
};

function Content({
  setClearVeto,
}: {
  setClearVeto: (clearVeto: () => void) => void;
}) {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);

  const room = params.get("room");
  const token = params.get("token");

  const vetoId = params.get("vetoId");
  const obsVetoId = params.get("obsVetoId");

  const [boType, setBo] = useState("bo3");

  const [matches, setMatches] = useState<MatchWithTeams[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);

  const [teamLeft, setTeamLeft] = useState<VetoTeamInfo>({
    name: "",
    logo: "",
    lhmId: null,
  });
  const [teamRight, setTeamRight] = useState<VetoTeamInfo>({
    name: "",
    logo: "",
    lhmId: null,
  });

  const [starting, setStarting] = useState<TeamType | null>(null);

  const [matchId, setMatchId] = useState<string | null>(null);

  const [step, setStep] = useState<Step>("game");
  const [mode, setMode] = useState<string | null>(null);
  const [game, setGame] = useState<'csgo' | 'valorant' | null>(null);

  const [unfinished, setUnfinished] = useState<VetoSetup[]>([]);

  const setShouldClear = useState(false)[1];

  const workspaces = useWorkspace();
  const vetos = useVetoHistory();

  const clearForm = () => {
    setMode(null);
    setShouldClear(true);
    setStep("game");
    setTeamLeft({ name: "", logo: "", lhmId: null });
    setTeamRight({ name: "", logo: "", lhmId: null });
    setStarting(null);
    setGame(null);
  };

  useEffect(() => {
    setClearVeto(() => {
      return clearForm;
    });
    if (
      location.pathname === `${config.basename}/app/lan` &&
      !location.search.startsWith("?vetoId=")
    ) {
      navigate("/app");
    }
  }, []);

  const loadMatches = (game: 'csgo' | 'valorant', workspace: number) => {
    api
      .getMatches(game, workspace)
      .then(({ data }) => {
        if (!data) return;
        const matches = data.matches || [];
        const teams = data.teams || [];
        setTeams(teams.map((team) => team.data));
        setMatches(
          (matches || []).map(mapMatchesAndTeam(teams.map((team) => team.data)))
        );
      })
      .catch(() => {});
  };

  const loadUnfinished = (workspace: number) => {
    api.unfinished(workspace).then(({ data }) => {
      setUnfinished(data?.vetos || []);
    });
  };

  const setMatchForVeto = (match: MatchWithTeams) => {
    setMatchId(match.id);
    setTeamLeft({
      name: match.left.data?.name || "Team One",
      logo: match.left.data?.logo || "",
      lhmId: match.left.data?._id || null,
    });
    setTeamRight({
      name: match.right.data?.name || "Team Two",
      logo: match.right.data?.logo || "",
      lhmId: match.right.data?._id || null,
    });
    setBo(match.matchType);
    setStep("mode");
  };

  const setModeAndProceed = (mode: string) => {
    setMode(mode);
    setStep("starting");
    /*
    if (mode === 'lan') {
      navigate('/lan')
    }*/
  };

  const setGameAndProceed = (game: 'valorant' | 'csgo') => {
    setGame(game);
    setStep("setup");
  }

  const setStartingAndProceed = (starting: TeamType) => {
    setStarting(starting);
    setStep("veto");

    if (starting === "right") {
      const newLeft = { ...teamRight };
      const newRight = { ...teamLeft };
      setTeamLeft(newLeft);
      setTeamRight(newRight);
    }
    setTimeout(() => {
      if (mode === "lan") {
        navigate("/app/lan");
      }
    }, 100);
  };

  const refreshMatches = () => {
    if (game) {
      loadMatches(game, workspaces.workspace?.id || 0);
    }
  }

  const refresh = () => {
    vetos.reloadVetos(workspaces.workspace?.id || 0);
    clearForm();
    loadUnfinished(workspaces.workspace?.id || 0);
  };

  useEffect(() => {
    refresh();
  }, [workspaces.workspace]);

  useEffect(() => {
    refreshMatches();
  }, [game, user.user, user.loading, workspaces.workspace])

  const changeWorkspace = (workspace: string) => {
    workspaces.setWorkspace(
      workspaces.workspaces?.find((space) => space.id === Number(workspace)) ||
        null
    );
  };

  useEffect(() => {
    if (obsVetoId || vetoId || (token && room)) {
      return;
    }
    if (user.loading) return;

    if (user.user) {
      loadUnfinished(0);
    } else navigate("/app/login");
  }, [user.user, user.loading]);

  useEffect(() => {
    if (obsVetoId || vetoId || (token && room)) {
      return;
    }
    if (!user.user) {
      navigate("/app/login");
      return;
    }
    refresh();
  }, [obsVetoId, vetoId, token, room]);

  if (obsVetoId || vetoId || (token && room)) {
    return (
      <>
        <VetoTitle>Veto</VetoTitle>
        <VetoHandler mode={vetoId ? "lan" : "XD"} matchId={matchId} />
        <ToastContainer />
      </>
    );
  }

  if (user.loading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    return <p>Not logged in</p>;
  }

  if (step === 'game') {
    return (
      <>
        <VetoTitle>Select Game</VetoTitle>
        <HeaderMenu status={"game"} />
        <div className="content mode-step">
          <div className="mode-chooser">
            <div onClick={() => setGame('csgo')}>
              <div
                className={`game-container ${game === "csgo" ? "active" : ""}`}
              >
                <CS2 />
              </div>
              Counter-Strike
            </div>
            <div onClick={() => setGame('valorant')}>
              <div
                className={`game-container ${
                  game === "valorant" ? "active" : ""
                }`}
              >
                <Valorant />
              </div>
              Valorant
            </div>
          </div>
          <Button
            disabled={!game}
            onClick={() => setGameAndProceed(game!)}
          >
            Choose game
          </Button>
        </div>
      </>
    );
  }  else if (step === "mode") {
    return (
      <>
        <VetoTitle>Create Veto</VetoTitle>
        <HeaderMenu status={"mode"} />
        <div className="content mode-step">
          <div className="mode-chooser">
            <div onClick={() => setMode("lan")}>
              <div
                className={`mode-container ${mode === "lan" ? "active" : ""}`}
              >
                <Lan shapeColor="#BDF2D5" />
              </div>
              LAN
            </div>
            <div onClick={() => setMode("online")}>
              <div
                className={`mode-container ${
                  mode === "online" ? "active" : ""
                }`}
              >
                <Online shapeColor="#BDF2D5" />
              </div>
              ONLINE
            </div>
          </div>
          <Button
            disabled={!mode}
            onClick={() => setModeAndProceed(mode as string)}
          >
            Choose mode
          </Button>
        </div>
      </>
    );
  }
  if (step === "starting") {
    return (
      <>
        <VetoTitle>First Pick</VetoTitle>
        <HeaderMenu status={"mode"} />
        <div className="content mode-step side-pick">
          <div className="mode-chooser side-pick">
            <TeamPick
              setStarting={() => setStarting("left")}
              active={starting === "left"}
              team={teamLeft}
            />
            <TeamPick
              setStarting={() => setStarting("right")}
              active={starting === "right"}
              team={teamRight}
            />
          </div>
          <Button
            disabled={!starting}
            onClick={() => setStartingAndProceed(starting as TeamType)}
          >
            Choose first to pick
          </Button>
        </div>
      </>
    );
  }

  if (step === "veto" && mode && teamLeft && teamRight && game) {
    return (
      <>
        <VetoTitle>Veto</VetoTitle>
        <VetoHandler
          bo={Number(boType.replace("bo", ""))}
          mode={mode}
          left={teamLeft}
          right={teamRight}
          matchId={matchId}
          game={game}
        />
        <ToastContainer />
      </>
    );
  }
  const blockMatches =
    user.user?.license.type === "free" && workspaces.workspace === null;
  return (
    <>
      <VetoTitle>Create Veto</VetoTitle>
      <HeaderMenu status={"veto"} />
      {workspaces.workspaces?.length ? (
        <div>
          <Select
            value={workspaces.workspace?.id || 0}
            onChange={changeWorkspace}
          >
            <option value={0}>Your workspace</option>
            {workspaces.workspaces
              .filter((workspace) => workspace.permissions.includes("Matches"))
              .map((workspace) => (
                <option value={workspace.id}>{workspace.name}</option>
              ))}
          </Select>
        </div>
      ) : null}
      {unfinished.length ? (
        <div className="unfinished-vetos">
          <p>You seem to have unfinished vetos:</p>
          {unfinished.map((veto) => (
            <div key={veto.id} className="entry">
              {veto.isLan ? (
                <LanEntry veto={veto} refresh={refresh} />
              ) : (
                <OnlineEntry veto={veto} refresh={refresh} />
              )}
            </div>
          ))}
        </div>
      ) : null}
      <div
        className="content"
        style={
          workspaces.workspaces?.length ? { marginTop: "-50px" } : undefined
        }
      >
        <MatchCreation
          boType={boType}
          setBo={setBo}
          teams={teams}
          setLeft={setTeamLeft}
          setRight={setTeamRight}
          left={teamLeft}
          right={teamRight}
          accept={() => setStep("mode")}
        />
        <MatchList
          blur={blockMatches}
          matches={blockMatches ? fakeMatches : matches}
          createVeto={setMatchForVeto}
        />
      </div>
      <ToastContainer />
    </>
  );
}

const OnlineEntry = ({
  veto,
  refresh,
}: {
  veto: VetoSetup;
  refresh: () => void;
}) => {
  return (
    <div className="online-entry">
      [ONLINE]&nbsp;
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CopyButton token={(veto.left as any).id} room={veto.id} />
          {veto.left.name}
        </div>
        <div className="versus">vs</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CopyButton token={(veto.right as any).id} room={veto.id} />
          {veto.right.name}
        </div>
        <div
          className="delete-button"
          style={{ cursor: "pointer" }}
          onClick={() => api.deleteVeto(veto.id, veto.ownerTeam).then(refresh)}
        >
          Delete
        </div>
      </div>
    </div>
  );
};

const LanEntry = ({
  veto,
  refresh,
}: {
  veto: VetoSetup;
  refresh: () => void;
}) => {
  return (
    <div className="lan-entry">
      [LAN]&nbsp;
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {veto.left.name}
        </div>
        <div className="versus">vs</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {veto.right.name}
        </div>
      </div>
      <CopyButton vetoId={veto.id} />
      <div
        className="delete-button"
        style={{ cursor: "pointer" }}
        onClick={() => api.deleteVeto(veto.id, veto.ownerTeam).then(refresh)}
      >
        Delete
      </div>
    </div>
  );
};

export default Content;
