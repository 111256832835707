import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useUser from "../../api/user";
import Hamburger from "../../assets/Hamburger";
import Logo from "./veto.png";
import "./index.scss";
import Arrow from "./../../assets/arrow.svg";
import config from "../../api/config";

const Header = ({ clearVeto }: { clearVeto: null | (() => void) }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useUser();
  const location = useLocation();

  const onlyLogo =
    location.pathname === `${config.basename}/app/lan` ||
    location.pathname === `${config.basename}/app/online/`;

  if (location.pathname === config.basename) {
    return null;
  }

  return (
    <>
      <div className="header">
        {!onlyLogo ? <div id="menu-void" /> : null}
        <div
          className="logo"
          style={
            onlyLogo ? { width: "100%", justifyContent: "center" } : undefined
          }
        >
          <a href="/">
            <img src={Logo} style={{ maxHeight: 100, maxWidth: 100 }} />
          </a>
        </div>
        {!onlyLogo ? (
          <Hamburger
            id="hamburger"
            onClick={() => setMenuOpen((prev) => !prev)}
          />
        ) : null}
        {!onlyLogo ? (
          <div className={`menu ${menuOpen ? "open" : ""}`}>
            <div
              className="close-navbar"
              onClick={() => setMenuOpen((prev) => !prev)}
            >
              &times;
            </div>
            <a href="/app" className="menu-button-custom">
              HOME
            </a>
            {user.user ? (
              <>
                <Link to="/app/history" className="menu-button-custom">
                  Veto history
                </Link>
              </>
            ) : null}
            {/*<Link to="/faq" className="menu-button-custom">FAQ</Link>*/}
            {user.user ? (
              <a
                href="https://lhm.gg/account/plan"
                className="menu-button-custom logged"
              >
                Hi, {user.user.username}{" "}
                <img src={Arrow} style={{ marginLeft: 18 }} />
              </a>
            ) : (
              <Link to="/app/login" className="menu-button-custom login">
                Log in
              </Link>
            )}
          </div>
        ) : null}
      </div>
      <div
        className={`overlay ${menuOpen ? "show" : ""}`}
        onClick={() => setMenuOpen((prev) => !prev)}
      />
    </>
  );
};

export default Header;
