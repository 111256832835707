import Copy from "../assets/Copy";
import { Logo } from "../Components/logo";
import { VetoTeamInfo } from "./VetoHandler";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "./Toast";
import { useState } from "react";

type Props = {
    team?: VetoTeamInfo | null,
    url: string
}

export const copyLink = (link: string) => {
    window.navigator.clipboard.writeText(link)
        .then(() => {
        })
        .catch(e => {
            console.log(e);
        })
};

const VetoLink = ({ team, url }: Props) => {
    const [toastText, setToastText] = useState("");
    const [toastId, setToastId] = useState(0);
    
    const showToast = (text: string) => {
      setToastText(text);
      setToastId(Math.random());
    };

    return <div className="veto-link-container">
        <div className="logo-container">
            {team?.logo && <Logo url={team.logo} />}
        </div>
        <div className="team-info">
            <div className="team-name small-text">{team?.name}</div>
            <div className="team-url">
                {url}
                <div className="copy-link-label" onClick={() => {copyLink(url); showToast('URL Copied!')}}>
                    <Copy />&nbsp;
                    Click & Copy
                </div>
            </div>
        </div>
        <Toast id={toastId} text={toastText} />
    </div>
}

export default VetoLink;