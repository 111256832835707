import "./index.scss";
import ReactSelect from 'react-select';
import React, { useState } from "react";

type Props = {
    value: string | number;
    onChange: (text: string) => void;
    placeholder?: string;
    label?: string;
    id?: string;
    type?: React.HTMLInputTypeAttribute,
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const Input = ({ value, onChange, placeholder, label, id, type, onKeyDown }: Props) => {

    return (
        <div className="input-container">
            { label && <label htmlFor={id}>{label}</label>}
            <input id={id} type={type || 'text'} onKeyDown={onKeyDown} onChange={e => onChange(e.target.value)} value={value} placeholder={placeholder}/>
        </div>
    )
}
const Select = ({ value, onChange, placeholder, label, id, children }: Props & { children: any }) => {

    return (
        <div className="input-container" id={id ? `${id}-container` : undefined}>
            { label && <label htmlFor={id}>{label}</label>}
            <select id={id} onChange={e => onChange(e.target.value)} value={value} placeholder={placeholder}>{children}</select>
        </div>
    )
}

export type Hint = {
    value: string, label: string
}

const delay = (func: () => void) => () => {
    setTimeout(func, 100);
}

export const InputWithHints = ({ value, onChange, placeholder, label, id, type, hints, onSelect }: Props & { onSelect: (hint: Hint) => void, hints?: Hint[] }) => {
    const [ showHints, setShowHints ] = useState(false);
    return (
        <div className="input-container">
            { label && <label htmlFor={id}>{label}</label>}
            <input id={id} type={type || 'text'} onChange={e => onChange(e.target.value)} value={value} placeholder={placeholder} onFocus={() => setShowHints(true)} onBlur={delay(() => setShowHints(false))}/>

            {hints?.length ? <div className={`hint-container ${showHints ? 'show':''}`}>
                {hints.map(hint => <div key={hint.value} className="hint" onClick={() => onSelect(hint)}>{hint.label}</div>)}
            </div> : null}
        </div>
    )
}

export { Select };
export default Input;