import useUser from "../../api/user";
import useVetoHistory from "../../api/vetos";
import { History } from "../../screens/History";
import { VetoTitle } from "../PageTitle";
import React, { useEffect } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";

export const AllHistory = () => {
  const vetoHistory = useVetoHistory();
  const user = useUser();
  const navigate = useNavigate();
  const vetos = vetoHistory.vetoHistory || [];

  useEffect(() => {
    if (user.loading) return;
    if (!user.user) {
      navigate("/app/login");
    }
  }, [user.user, user.loading]);

  return (
    <>
      <VetoTitle>Veto History</VetoTitle>
      <div className="content history-content">
        <div
          className="veto-history-entry lms"
          style={{ borderBottom: "none" }}
        >
          <div className="heading">
            <div className="veto-entry-teams">Teams</div>
            <div className="veto-entry-maps">Maps</div>
            <div className="veto-entry-date">Date</div>
          </div>
          <div className="liner" />
        </div>
        {vetos.map((veto) => (
          <History key={veto.id} veto={veto} />
        ))}
      </div>
    </>
  );
};
